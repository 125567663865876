import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { StringUtil } from '@shared/utils/string.util';
import { OAuthService } from 'angular-oauth2-oidc';
import * as objectHash from 'object-hash';
import { expand, filter, map, Observable, reduce, shareReplay, takeWhile, tap, throwIfEmpty } from 'rxjs';
import { clientId } from './constants';
import { DataResponse } from './types/data-response.model';
import { Follow } from './types/follow.model';
import { FollowCacheKey } from './types/follow-cache-key.model';
import { HttpHeaderBuilder } from './types/http-header.builder';
import { User } from './types/user.model';

/**
 * Angular Service to access endpoints from the 'users' namespace
 */
@Injectable({
  providedIn: 'root'
})
export class UsersService {

  /**
   * Attribute of the url of the endpoint to request user objects.
   */
  private USERS_ENDPOINT = `${environment.apiBaseUrl}/users`;

  /**
   * Attribute of the url of the endpoint to request follows of the logged in user.
   */
  private FOLLOWS_ENDPOINT = `${environment.apiBaseUrl}/users/follows`;

  /**
   * Cached attribute of the logged in user.
   */
  private _loggedInUser?: User;

  /**
   * Cached map of the follows of the logged in user.
   * {@link expand} calls recursively all follows in {@link _follows}
   * and the recursive call causes that queries a executed multiple times.
   */
  private followsCache = new Map<string, Observable<DataResponse>>();

  /**
   * @constructor
   * Constructor to inject needed services.
   *
   * @param {HttpClient} httpClient http client to execute requests
   * @param {OAuthService} authService oauth authorization service
   */
  constructor(private httpClient: HttpClient, private authService: OAuthService) { }

  /**
   * Method to request a array of {@link User} from the twitch API without passing any payload to the request.
   * This request should repsonse with the logged in user.
   *
   * The request will cache the responsed {@link User} in a private attribute
   * to deliver the logged in user fast when requesting multiple times
   *
   * @returns {Observable} {@link Observable} who pass the logged in {@link User} to all observers.
   */
  loggedInUser(): Promise<User> {
    return new Promise<User>((resolve, reject) => {
      if (this._loggedInUser) {
        resolve(this._loggedInUser);
      } else {
        this.observeLoggedInUserResponse().subscribe({ next(user) { resolve(user); }, error() { reject(undefined); } });
      }
    });
  }

  /**
   * Method to request a array of {@link User} from the twitch API.
   * The returned {@link Observable} will provide the first entry of the array when the array has a exact size of one.
   *
   * @returns {Observable} {@link Observable} who pass the logged in {@link User} to all observers.
   */
  private observeLoggedInUserResponse(): Observable<User> {
    return this.users().pipe(
      filter(users => users.length === 1),
      throwIfEmpty(),
      map(users => users[0]),
      tap(user => this._loggedInUser = user)
    );
  }

  /**
   * Method to request a array of {@link User} from the twitch API by passing all given unique user ids.
   *
   * @param {string[]} ids array of unqiue user ids to pass ass payload to the request.
   * @returns {Observable} {@link Observable} who pass {@link DataResponse} with a {@link User} array as data to all observers.
   */
  users(ids: string[] = []): Observable<User[]> {
    const params: HttpParams = ids.reduce((params, id) => params.append('id', id), new HttpParams());
    return this.httpClient.get<DataResponse>(this.USERS_ENDPOINT, { headers: this.header, params })
      .pipe(map(response => response.data as User[]));
  }

  /**
   * Method to request a array of {@link Follow} from the given unique user id from the twitch API.
   *
   * @param {string} userId unique id of the user to pass as payload to the request
   * @returns {Observable} {@link Observable} who pass a array of {@link Follow} to all observers.
   */
  public follows(userId: string): Observable<Follow[]> {
    const _merge = (accumulator: Follow[], current: Follow[]) => {
      accumulator.push(...current);
      return accumulator;
    };
    return this._follows(userId).pipe(
      map(response => response.data as Follow[]),
      reduce(_merge));
  }
  /**
   * Private method to scroll through any page of the user follows. Every page will be emitted separately.
   * A Cache is implemented with {@link shareReplay} with a max cache time of one minute.
   *
   * @param {string} userId unique id of the user to pass as payload to the request
   * @param cursor hash value of the page to request
   * @returns {Observable} {@link Observable} who pass a array of {@link DataResponse} to all observers.
   */
  private _follows(userId: string, cursor = ''): Observable<DataResponse> {
    const params = new HttpParams().set('from_id', userId).set('after', cursor);
    const key: FollowCacheKey = { userId, cursor };
    let request = this.followsCache.get(objectHash.sha1(key));
    if (!request) {
      request = this.httpClient.get<DataResponse>(this.FOLLOWS_ENDPOINT, { headers: this.header, params }).pipe(
        shareReplay(Infinity, 60000),
        expand(response => this._follows(userId, response.pagination.cursor)),
        takeWhile(response => !StringUtil.isEmpty(response.pagination.cursor), true),
      );
      this.followsCache.set(objectHash.sha1(key), request);
    }
    return request;
  }

  /**
   * Provides a object of type {@link HttpHeaders} with the headers for {@link clientId} and {@link OAuthService.getAccessToken}.
   */
  private get header() {
    return HttpHeaderBuilder.builder().withClientId(clientId).withAuthorization(this.authService.getAccessToken()).build();
  }
}
