import { HttpHeaders } from '@angular/common/http';

/**
 * Builder class to build a object of {@link HttpHeaders}.
 */
export class HttpHeaderBuilder {

  /**
   * Object with the key/value pairs to set as headers in the HTTP request.
   */
  private headers: { [name: string]: string | string[] } = {};

  /**
   * Method to create a new instance of this builder.
   * @returns new instance of {@link HttpHeaderBuilder}
   */
  static builder(): HttpHeaderBuilder {
    return new HttpHeaderBuilder();
  }

  /**
   * Created object will have the given value in HTTP header 'Client-Id'.
   *
   * @param clientId value to store used during build
   * @returns instance of this builder
   */
  withClientId(clientId: string) {
    this.headers['Client-Id'] = clientId;
    return this;
  }

  /**
   * Created object will have the given value in HTTP header 'Authorization' with the prefix 'Bearer'.
   *
   * @param token value to store used during build
   * @returns instance of this builder
   */
  withAuthorization(token: string): HttpHeaderBuilder {
    this.headers['Authorization'] = `Bearer ${token}`;
    return this;
  }

  /**
   * Method to create a object of {@link HttpHeaders} with the previous passed values.
   *
   * @returns created object as {@link HttpHeaders}
   */
  build(): HttpHeaders {
    return new HttpHeaders(this.headers);
  }
}
