import { environment } from '@environment/environment';
import { AuthConfig } from 'angular-oauth2-oidc';

/**
 * Builder class to create a object of {@link AuthConfig}
 */
export class AuthConfigBuilder {

  /**
   * Attribute of the issuer of the oauth token
   */
  private issuer?: string;

  /**
   * Attribute of the url to revoke an cached token.
   */
  private revocationEndpoint?: string;

  /**
   * Attribute of the client id of this application to pass to every request.
   */
  private clientId?: string;

  /**
   * Attribute of the url to redirect to after a successful login
   */
  private redirectUri?: string;

  /**
   * Attribute of the scopes to pass during authentication.
   */
  private scope?: string;

  /**
   * Method to create a new instance of this builder.
   * @returns new instance of {@link AuthConfigBuilder}
   */
  static getBuilder(issuer: string): AuthConfigBuilder {
    return new AuthConfigBuilder(issuer);
  }

  /**
   * @constructor
   * Default constructor for this builder. Every {@link AuthConfig} need to have the given string as issuer.
   * @param issuer value to set as {@link AuthConfig.issuer} to the created object with this builder
   */
  constructor(issuer: string) {
    this.issuer = issuer;
  }

  /**
   * Created object of this builder will set the given value to {@link AuthConfig.revocationEndpoint}
   *
   * @param revocationEndpoint value to store used during build
   * @returns instance of this builder
   */
  withRevocationEndpoint(revocationEndpoint: string): AuthConfigBuilder {
    this.revocationEndpoint = revocationEndpoint;
    return this;
  }

  /**
   * Created object of this builder will set the given value to {@link AuthConfig.clientId}
   *
   * @param clientId value to store used during build
   * @returns instance of this builder
   */
  withClientId(clientId: string): AuthConfigBuilder {
    this.clientId = clientId;
    return this;
  }

  /**
   * Created object of this builder will set the given value to {@link AuthConfig.redirectUri}
   *
   * @param redirectUri value to store used during build
   * @returns instance of this builder
   */
  withRedirectUri(redirectUri: string): AuthConfigBuilder {
    this.redirectUri = redirectUri;
    return this;
  }

  /**
   * Created object of this builder will set the given value to {@link AuthConfig.scope}
   *
   * @param scope value to store used during build
   * @returns instance of this builder
   */
  withScope(scope: string): AuthConfigBuilder {
    this.scope = scope;
    return this;
  }

  /**
   * Method to create a object of {@link AuthConfig} with the previous passed values.
   * The created object has {@link AuthConfig.clearHashAfterLogin} set to `true`.
   *
   * @returns created object as {@link AuthConfig}
   */
  build(): AuthConfig {
    return new AuthConfig({
      issuer: this.issuer,
      loginUrl: environment.loginUrl,
      revocationEndpoint: this.revocationEndpoint,
      clientId: this.clientId,
      redirectUri: this.redirectUri,
      scope: this.scope,
      clearHashAfterLogin: true
    });
  }
}
