import { Component } from '@angular/core';

/**
 * Root component of the 'unauthorized' page with selector 'unauthorized'.
 */
@Component({
  selector: 'unauthorized',
  templateUrl: './unauthorized.component.html'
})
export class UnauthorizedComponent { }
