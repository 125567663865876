import { Component, forwardRef } from '@angular/core';
import { AnchorComponent } from '@shared/components/anchor.component';


/**
 * Angular component for a anchor item inside the navigation sidebar.
 * A provider for {@link AnchorComponent} is specified to inject this component to
 * {@link AnchorDirective}.
 */
@Component({
  selector: 'app-sidebar-item',
  templateUrl: './sidebar.item.component.html',
  // eslint-disable-next-line no-use-before-define
  providers: [{ provide: AnchorComponent, useExisting: forwardRef(() => SidebarItemComponent) }]
})
export class SidebarItemComponent extends AnchorComponent { }
