import { Injectable } from '@angular/core';
import { CanActivateChild, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';

/**
 * Angular service which implements {@link CanActivateChild} to use it as routing guard for the twitch API.
 */
@Injectable()
export class TwitchGuard implements CanActivateChild {

  /**
   * @constructor
   * Constructor to inject needed services.
   *
   * @param {Router} router router service to execute routing events
   * @param {OAuthService} authService oauth authorization service
   */
  constructor(private router: Router, private authService: OAuthService) { }

  /**
   * Method will return `true` when the {@link OAuthService} can provide a valid token.
   * If no valid token can be provided a {@link Router} event will be trigger to navigate to 'unauthorized'.
   *
   * @returns `true` if {@link OAuthService} can provide a valid token otherwise `false`
   */
  canActivateChild() {
    const validIdToken = this.authService.hasValidIdToken();
    if (!validIdToken) {
      this.router.navigate(['unauthorized']);
    }
    return validIdToken;
  }
}
