import { Component, OnInit } from '@angular/core';
import { ActivationEnd, NavigationStart, Router } from '@angular/router';
import { RoutingData } from '@shared/types/routing-data.model';
import { OAuthService } from 'angular-oauth2-oidc';
import { filter, map, Observable } from 'rxjs';

/**
 * Angular component to render and interact with the header.
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  /**
   * Attribute of the text to render as current page name.
   */
  pagename?: string;

  /**
   * @constructor
   * Constructor to inject router service
   *
   * @param {Router} router router service to execute routing events
   * @param {OAuthService} authService oauth authorization service
   */
  constructor(private router: Router, private authService: OAuthService) { }

  /**
   * @inheritDoc {@link OnInit.ngOnInit}
   *
   * Method will subscribe to routing events to reset page name on {@link NavigationStart}
   * and sets the new page name on {@link ActivationEnd}.
   */
  ngOnInit() {
    // clear pagename on navigation start
    this.observeNavigationStart().subscribe(() => this.pagename = '');
    // set pagename by activated route
    this.observeActivationEnd().subscribe(data => this.pagename = data.title);
  }

  /**
   * Filters routing events for all instances of NavigationStart
   * @returns {Observable}
   */
  private observeNavigationStart(): Observable<NavigationStart> {
    return this.router.events.pipe(
      filter(event => event instanceof NavigationStart),
      map(event => event as NavigationStart)
    );
  }

  /**
   * Method produces a Observable for routing events for events of type {@link ActivationEnd}
   * and maps it to {@link RoutingData} by accessing data of {@link ActivatedRouteSnapshot}
   * @returns {Observable}
   */
  private observeActivationEnd(): Observable<RoutingData> {
    return this.router.events.pipe(
      filter(event => event instanceof ActivationEnd),
      map(event => event as ActivationEnd),
      map(event => event.snapshot),
      map(snapshot => snapshot.data as RoutingData),
      filter(data => data.title !== undefined)
    );
  }

  /**
   * Method to execute the oauth login flow.
   */
  auth() {
    this.authService.initLoginFlow();
  }

  /**
   * Getter to return a boolean value which will be `true` if a valid access token exists.
   */
  get loggedIn(): boolean {
    return this.authService.hasValidIdToken();
  }

  /**
   * Method to revoke a existing access token and triggers a logout at the authorization server.
   */
  revokeToken() {
    this.authService.revokeTokenAndLogout({}, true).catch(() => this.authService.logOut())
      .finally(() => window.location.href = window.location.origin);
  }
}
