<mat-toolbar color="primary">
    <mat-toolbar-row>
        <button sidebar-switch class="mat-transparent-button" mat-icon-button aria-label="menu">
            <mat-icon>menu</mat-icon>
        </button>
        <span class="flex-grow">{{pagename}}</span>
        <ng-container *ngIf="loggedIn; else login">
            <button logout-button class="mat-transparent-button" mat-icon-button (click)="revokeToken()" aria-label="logout">
                <mat-icon>logout</mat-icon>
            </button>
        </ng-container>
        <ng-template #login>
            <button login-button class="mat-transparent-button" mat-icon-button (click)="auth()" aria-label="login">
                <mat-icon>login</mat-icon>
            </button>
        </ng-template>
    </mat-toolbar-row>
</mat-toolbar>