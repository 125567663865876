import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '@environment/environment';
import { UnauthorizedComponent } from '@shared/unauthorized.component';

/**
 * Root router routes
 */
const routes: Routes = [
  { path: '', loadChildren: () => import('./pages/page.module').then(m => m.PageModule) },
  { path: 'unauthorized', component: UnauthorizedComponent }
];

/**
 * Root routing module to present different pages in the browser.
 * Using hashes for routing can be configured in {@link environment.useHash} and is needed for gitlab pages.
 */
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: environment.useHash, initialNavigation: 'disabled' })
  ],
  exports: [
    RouterModule
  ]
})
export class RoutingModule { }
