
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@root/material.module';
import { NgApexchartsModule } from 'ng-apexcharts';
import { AnchorComponent } from './components/anchor.component';
import { SortableTableComponent } from './components/sortable-table.component';
import { TestBarChartComponent } from './components/test-bar-chart.component';
import { TestTreeComponent } from './components/test-tree.component';
import { VulnerabilityOverviewComponent } from './components/vulnerability-overview.component';
import { AnchorDirective } from './directives/anchor.directive';
import { ConfidenceFilterDirective } from './directives/confidence.filter.directive';
import { TableDetailsOpenDirective } from './directives/table-details.open.directive';
import { TwitchGuard } from './routing/twitch-guard.service';
import { ScheduleService } from './twitch-api/schedule.service';
import { SearchService } from './twitch-api/search.service';
import { UsersService } from './twitch-api/users.service';

/**
 * Angular module to provide shared directives or components.
 * This module provides the services {@link UsersService}, {@link SearchService}, {@link ScheduleService}, {@link TwitchGuard}
 * and exports the declarables {@link AnchorDirective}, {@link TableDetailsOpenDirective},
 * {@link SortableTableComponent}, {@link AnchorComponent}.
 */
@NgModule({
  declarations: [
    AnchorDirective,
    TableDetailsOpenDirective,
    ConfidenceFilterDirective,
    SortableTableComponent,
    VulnerabilityOverviewComponent,
    TestBarChartComponent,
    TestTreeComponent,
    AnchorComponent
  ],
  providers: [
    UsersService,
    SearchService,
    ScheduleService,
    TwitchGuard
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    NgApexchartsModule
  ],
  exports: [
    AnchorDirective,
    TableDetailsOpenDirective,
    SortableTableComponent,
    VulnerabilityOverviewComponent,
    TestBarChartComponent,
    TestTreeComponent,
    AnchorComponent
  ]
})
export class SharedModule { }
