/**
 * Utility class for strings.
 */
export class StringUtil {

  /**
   * @constructor
   * Private default constructor to restrict instantiation of the utility class.
   * This utility class should contain only static methods.
   */
  private constructor() {
    throw new Error('instantiation not allowed. It\'s just a utility class with static methods');
  }

  /**
   * Static method to check if the given text is empty.
   * If the given text is `undefined`, `null` or `''` this method will return `true`.
   *
   * @param {string | null} text the text to check for empty value
   * @returns {boolean} `true` if given text is empty otherwise `false`
   */
  static isEmpty(text?: string | null): boolean {
    return text === undefined || text === null || text === '';
  }
}
