import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { StringUtil } from '@shared/utils/string.util';
import { OAuthService } from 'angular-oauth2-oidc';
import { expand, map, Observable, reduce, takeWhile } from 'rxjs';
import { clientId } from './constants';
import { DataResponse } from './types/data-response.model';
import { HttpHeaderBuilder } from './types/http-header.builder';
import { Schedule } from './types/schedule.model';

/**
 * Angular Service to access endpoints from the 'schedule' namespace
 */
@Injectable()
export class ScheduleService {

  /**
   * Attribute of the url of the endpoint to request a schedule of a member.
   */
  private SCHEDULE_ENDPOINT = `${environment.apiBaseUrl}/schedule`;

  /**
   * @constructor
   * Constructor to inject needed services.
   *
   * @param {HttpClient} httpClient http client to execute requests
   * @param {OAuthService} authService oauth authorization service
   */
  constructor(private httpClient: HttpClient, private authService: OAuthService) { }

  /**
   * Method to request a {@link Schedule} from the given unique user id from the twitch API.
   *
   * @param {string} userId unique id of the user to pass as payload to the request
   * @returns {Observable} {@link Observable} who pass a {@link Schedule} to all observers.
   */
  public schedule(userId: string): Observable<Schedule> {
    return this.produceScheduleRequest(this.header, this.required(userId))
      .pipe(map(response => response.data as Schedule));
  }

  /**
   * Method to request a {@link Schedule} from the given unique user id inside the given period from the twitch API.
   *
   * @param {string} userId unique id of the user to pass as payload to the request
   * @param {Date} from start date of the date range to request
   * @param {Date} to end date of the date range to request
   * @returns {Observable} {@link Observable} who pass a {@link Schedule} to all observers.
   */
  public scheduleOfPeriod(userId: string, from: Date, to: Date): Observable<Schedule> {
    const _merge = (accumulator: Schedule, current: Schedule) => {
      accumulator['broadcaster_id'] = current.broadcaster_id;
      accumulator['broadcaster_name'] = current.broadcaster_name;
      accumulator['broadcaster_login'] = current.broadcaster_login;
      accumulator.segments.push(...current.segments);
      return accumulator;
    };
    return this._scheduleOfPeriod(userId, from, to).pipe(
      map(response => response.data as Schedule),
      reduce(_merge),
    );
    // return this._scheduleOfPeriod(userId, from, to)
    //   .pipe(map(response => response.data as Schedule));
  }

  /**
   * Private method to request a {@link Schedule} from the given unique user id inside the given period from the twitch API.
   * The twitch API only provides segments after a given date, but you can't set a end date for the last segment to provide.
   * Therefore this method will recursively request the next page until all segments are provided
   * or the end date has been reached by any provided segment.
   *
   * @param {string} userId unique id of the user to pass as payload to the request
   * @param {Date} from start date of the date range to request
   * @param {Date} to end date of the date range to request
   * @param {string} cursor identifier for the previously requested page browse the following page.
   * @returns {Observable} {@link Observable} who pass a {@link DataResponse} to all observers.
   */
  private _scheduleOfPeriod(userId: string, from: Date, to: Date, cursor = ''): Observable<DataResponse> {
    const params = this.required(userId).set('start_time', from.toISOString()).set('after', cursor);
    return this.produceScheduleRequest(this.header, params).pipe(
      expand(response => this._scheduleOfPeriod(userId, from, to, response.pagination.cursor)),
      takeWhile(response => {
        const data: Schedule = response.data as Schedule;
        const toDateReached = data.segments.some(segment => new Date(segment.start_time).getTime() >= to.getTime());
        return !StringUtil.isEmpty(response.pagination.cursor) && !toDateReached;
      }, true),
    );
  }

  /**
   * Produces a HTTP request as {@link Observable} with the given headers and params.
   * The Request will be send to {@link SCHEDULE_ENDPOINT}.
   *
   * @param {HttpHeaders} headers the header values to set in the request
   * @param {HttpParams} params the params to set as payload in the request
   * @returns {Observable} {@link Observable} who pass a {@link DataResponse} to all observers.
   */
  private produceScheduleRequest(headers: HttpHeaders, params: HttpParams): Observable<DataResponse> {
    return this.httpClient.get<DataResponse>(this.SCHEDULE_ENDPOINT, { headers, params });

  }

  /**
   * Provides a object of type {@link HttpHeaders} with the headers for {@link clientId} and {@link OAuthService.getAccessToken}.
   */
  private get header() {
    return HttpHeaderBuilder.builder().withClientId(clientId).withAuthorization(this.authService.getAccessToken()).build();
  }

  /**
   * Provides a object of type {@link HttpParams} with all required values which need to pass to every request to the schedule endpoints.
   *
   * @param {string} userId unique id of a user which will be set as param 'broadcaster_id'.
   * @returns {HttpParams} created object of type {@link HttpParams} with all required params
   */
  private required(userId: string): HttpParams {
    return new HttpParams().set('broadcaster_id', userId);
  }
}
