/**
 * Scope to request access to the follows of the logged in user.
 */
const readFollows = 'user:read:follows';

/**
 * Array of scopes to pass to authorization server during authorize.
 */
export const scopes = [
  readFollows
];

/**
 * Client id for the twitch API to use for requesting data from the API.
 */
export const clientId = 'jjlk73s78ncz1mk8er7j7qhkcs1b2z';
