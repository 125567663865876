import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { OAuthService } from 'angular-oauth2-oidc';
import { map, Observable } from 'rxjs';
import { clientId } from './constants';
import { Channel } from './types/channel.model';
import { DataResponse } from './types/data-response.model';
import { HttpHeaderBuilder } from './types/http-header.builder';

/**
 * Angular Service to access endpoints from the 'schedule' namespace
 */
@Injectable()
export class SearchService {

  /**
   * Attribute of the url of the endpoint to find/search channels
   */
  private SEARCH_ENDPOINT = `${environment.apiBaseUrl}/search/channels`;

  /**
   * @constructor
   * Constructor to inject needed services.
   *
   * @param {HttpClient} httpClient http client to execute requests
   * @param {OAuthService} authService oauth authorization service
   */
  constructor(private httpClient: HttpClient, private authService: OAuthService) { }

  /**
   * Method to request a array of {@link Channel} for the given query text from the twitch API.
   *
   * @param {string} query text to pass as query to find channels
   * @returns {Observable} {@link Observable} who pass a array of {@link Channel} to all observers.
   */
  public search(query: string): Observable<Channel[]> {
    const headers = HttpHeaderBuilder.builder().withClientId(clientId).withAuthorization(this.authService.getAccessToken()).build();
    const params = new HttpParams().set('query', query);
    return this.httpClient.get<DataResponse>(this.SEARCH_ENDPOINT, { headers, params })
      .pipe(map(response => response.data as Channel[]));
  }
}
