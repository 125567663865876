import { environment } from '@environment/environment';
import { clientId, scopes } from '@shared/twitch-api/constants';
import { AuthConfig } from 'angular-oauth2-oidc';
import { AuthConfigBuilder } from './auth-config.builder';

/**
 * Factory class to supply objects of {@link AuthConfig}
 */
export class AuthConfigFactory {

  /**
   * Method to supply the default configuration for {@link AuthService}.
   *
   * @returns object of {@link AuthConfig} with default configuration
   */
  static default(): AuthConfig {
    return AuthConfigBuilder.getBuilder('https://id.twitch.tv/oauth2').withRevocationEndpoint(environment.revokeUrl)
      .withClientId(clientId).withRedirectUri(window.location.origin).withScope(scopes.join(' ')).build();
  }
}
