import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '@environment/environment';
import { OAuthModule } from 'angular-oauth2-oidc';
import {
  NgcCookieConsentConfig,
  NgcCookieConsentModule,
  NgcCookieConsentService
} from 'ngx-cookieconsent';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { MaterialModule } from './material.module';
import { RoutingModule } from './routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';

/**
 * Attribute to configure {@link NgcCookieConsentModule}
 */
const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    // eslint-disable-next-line id-denylist
    domain: window.location.hostname,
    secure: true
  },
  palette: {
    popup: {
      background: '#222'
    },
    button: {
      background: '#6441a5'
    }
  },
  theme: 'edgeless',
  type: 'info'
};

/**
 * Application bootstrap module which will be used as entry point in the web page.
 */
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    NgcCookieConsentModule.forRoot(cookieConfig),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RoutingModule,
    NgxGoogleAnalyticsModule.forRoot(environment.ga),
    NgxGoogleAnalyticsRouterModule,
    OAuthModule.forRoot(),
    MaterialModule,
    LayoutModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(private ccService: NgcCookieConsentService) { }
}
